<template>
    <div
        class="md-layout-item md-large-size-100 md-medium-size-100 md-small-size-100 md-xsmall-size-100"
    >
        <vue-pull-refresh
            v-if="isMobile && isIOS"
            :on-refresh="onRefresh"
            :config="pullConfig"
            :class="{
                refreshMobile: !isInstalled
            }"
        >
            <mobile
                v-if="carrierRequests.length"
                :data="carrierRequests"
                :passengersStatuses="passengersStatuses"
                :loading="loading"
                :open="open"
                @call="checkCreditAndCall"
                @hasBeenTaken="hasBeenTaken"
            />

            <div
                :class="{
                    mtdefault: isInstalled,
                    mtdefaultMobile: !isInstalled
                }"
            >
                <tips class="pl16 pr16 pb16" style="padding-top:16px;" />
            </div>
        </vue-pull-refresh>

        <mobile
            v-if="isMobile && !isIOS"
            :data="carrierRequests"
            :passengersStatuses="passengersStatuses"
            :loading="loading"
            :open="open"
            @call="checkCreditAndCall"
            @hasBeenTaken="hasBeenTaken"
        />

        <desktop
            v-if="!isMobile && carrierRequests.length"
            :data="carrierRequests"
            :passengersStatuses="passengersStatuses"
        />

        <div
            v-if="!isIOS"
            :class="{
                mtdefault: isInstalled,
                mtdefaultMobile: !isInstalled
            }"
        >
            <tips class="pl16 pr16 pb16" style="padding-top:16px;" />
        </div>

        <div class="loading-overlay" v-if="showLoading">
            <div class="wrapper">
                <md-progress-spinner md-mode="indeterminate" :md-stroke="2" />
            </div>
        </div>

        <snackbar :show="isTakenByOther" :text="passengerHasBeenTakenBy" />
        <snackbar :show="showCalling" :text="passengerIsTalkingMessage" />
        <too-little-credit-modal
            :show="tooLittleCredit"
            :carrierUUID="currentUser.carrierUUID"
            @closeTooLittleCredit="() => (tooLittleCredit = false)"
        />
    </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import Mobile from '../components/Home/mobile';
import Desktop from '../components/Home/desktop';
import TooLittleCreditModal from '../components/modals/tooLittleCreditModal';
import Snackbar from '../components/elements/Snackbar';
import Tips from '../components/elements/Tips';
import config from '@config';
const io = require('socket.io-client');
const socket = io(`${config.socketUrl}`);
const VuePullRefresh = require('vue-pull-refresh');

export default {
    components: {
        'vue-pull-refresh': VuePullRefresh,
        Mobile,
        Desktop,
        Snackbar,
        TooLittleCreditModal,
        Tips
    },

    data() {
        return {
            showLoading: true,
            open: false,
            loading: false,
            tooLittleCredit: false,
            showCalling: false,
            isTakenByOther: false,
            takenByCarrier: '',
            price: { call: 5, take: 55 },
            pullConfig: {
                errorLabel: '',
                startLabel: '',
                readyLabel: '',
                loadingLabel: ''
            }
        };
    },

    computed: {
        ...mapGetters({
            currentUser: 'auth/currentUser',
            carrierRequests: 'requests/carrierRequests',
            websiteIsOpen: 'settings/websiteOpen',
            passengersStatuses: 'requests/passengersStatuses'
        }),

        isMobile() {
            if (
                /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                    navigator.userAgent
                )
            ) {
                return true;
            } else {
                return false;
            }
        },

        isInstalled() {
            return window.navigator.standalone === true;
        },

        isIOS() {
            return /iPhone|iPad|iPod/i.test(navigator.userAgent);
        },

        passengerIsTalkingMessage() {
            return this.$t('global.carrierCallsPassenger');
        },

        passengerHasBeenTakenBy() {
            return `${this.$t('global.passengerHasBeenTakenBy')} ${
                this.takenByCarrier
            }`;
        }
    },

    methods: {
        ...mapMutations({
            addRequest: 'requests/ADD_REQUEST',
            websiteOpen: 'settings/WEBSITE_OPEN',
            counterAdd: 'auth/COUNTER_ADD',
            clear: 'requests/CLEAR_LAST_REQUESTS'
        }),

        ...mapActions({
            refreshToken: 'auth/refreshToken',
            getPhone: 'content/getPhone',
            getCalled: 'content/getCalled',
            getAnyCall: 'content/getAnyCall',
            getCarrier: 'auth/validate',
            returnCarrier: 'content/returnCarrier',
            lastCarrierRequests: 'requests/lastCarrierRequests',
            getPassengersStatuses: 'requests/getPassengersStatuses'
        }),

        async onRefresh() {
            await this.lastCarrierRequests(this.currentUser.carrierUUID);

            return new Promise(function(resolve, reject) {
                setTimeout(function() {
                    resolve();
                }, 1000);
            });
        },

        async connectToSocket() {
            socket.emit('for_server_carrer_requests', {
                request_uuid: this.currentUser.carrierUUID
            });

            socket.on('for_client_carrer_requests', async data => {
                const { request_uuid } = data;
                const arr = [];

                if (request_uuid == this.currentUser.carrierUUID) {
                    arr.push(request_uuid);
                    this.getPassengersStatuses(JSON.stringify(arr));
                    this.addRequest(data);
                }
            });

            if (localStorage.openingCounter < 5 && this.isMobile) {
                this.open = true;

                setTimeout(() => {
                    this.open = false;
                }, 1000);

                this.counterAdd();
            }
        },

        checkCreditAndCall(e) {
            if (this.currentUser.credit < this.price.call) {
                this.tooLittleCredit = true;
            } else {
                this.callToPassenger(e);
            }
        },

        async callToPassenger(e) {
            this.loading = true;
            const msg = [{ cmd: 'call' }];
            const anyCall = await this.getAnyCall(e.uuid);
            const { carrierUUID } = this.currentUser;
            const { call: iCall } = await this.getCalled({
                carrierUUID,
                inquiryUUID: e.uuid
            });

            if (iCall) {
                const response = await this.getPhone(e.uuid);
                if (response) {
                    this.loading = false;
                    location.href = 'tel:' + response.phone;
                }
            } else if (!!anyCall && anyCall.carrier !== carrierUUID) {
                this.showCalling = true;
                this.loading = false;
            } else {
                this.loading = false;
                socket.emit('for_server', {
                    data: msg,
                    request_uuid: e.uuid,
                    uuid: carrierUUID + '.' + e.uuid
                });
                const response = await this.getPhone(e.uuid);
                if (response) {
                    this.loading = false;
                    location.href = 'tel:' + response.phone;
                }
            }
        },

        checkCredit() {
            if (this.currentUser.credit < this.price.take) {
                this.tooLittleCredit = true;
            }
        },

        async hasBeenTaken(item) {
            this.loading = true;
            const { company_name } = await this.returnCarrier(item.taken_by);

            this.takenByCarrier = company_name;
            this.loading = false;
            this.isTakenByOther = true;

            setTimeout(() => {
                this.isTakenByOther = false;
            }, 3000);
        },

        async handleVisibilityChange() {
            if (
                /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                    navigator.userAgent
                ) &&
                document.visibilityState === 'visible'
            ) {
                this.showLoading = true;
                this.clear();

                try {
                    await this.lastCarrierRequests(this.carrierUUID);
                    this.showLoading = false;
                } catch (error) {
                    this.showLoading = false;
                }
                socket.disconnect();
                socket.connect();
            }
        },

        scrollToTop() {
            window.scrollTo(0, 0);
        }
    },

    beforeDestroy() {
        window.removeEventListener(
            'visibilitychange',
            this.handleVisibilityChange
        );
    },

    async mounted() {
        if (!this.websiteIsOpen) {
            await this.refreshToken();
            this.websiteOpen(true);
        }

        this.scrollToTop();

        await this.getCarrier();
        await this.checkCredit();

        const { carrierUUID } = this.currentUser;

        await this.lastCarrierRequests(carrierUUID);
        await this.connectToSocket();
        this.showLoading = false;

        window.addEventListener(
            'visibilitychange',
            this.handleVisibilityChange
        );

        this.$gtag.config({
            cookie_prefix: 'GA_MEASUREMENT_ID',
            cookie_expires: 28 * 24 * 60 * 60,
            user_id: carrierUUID
        });
    }
};
</script>
