var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"md-layout-item md-large-size-100 md-medium-size-100 md-small-size-100 md-xsmall-size-100"},[(_vm.isMobile && _vm.isIOS)?_c('vue-pull-refresh',{class:{
            refreshMobile: !_vm.isInstalled
        },attrs:{"on-refresh":_vm.onRefresh,"config":_vm.pullConfig}},[(_vm.carrierRequests.length)?_c('mobile',{attrs:{"data":_vm.carrierRequests,"passengersStatuses":_vm.passengersStatuses,"loading":_vm.loading,"open":_vm.open},on:{"call":_vm.checkCreditAndCall,"hasBeenTaken":_vm.hasBeenTaken}}):_vm._e(),_c('div',{class:{
                mtdefault: _vm.isInstalled,
                mtdefaultMobile: !_vm.isInstalled
            }},[_c('tips',{staticClass:"pl16 pr16 pb16",staticStyle:{"padding-top":"16px"}})],1)],1):_vm._e(),(_vm.isMobile && !_vm.isIOS)?_c('mobile',{attrs:{"data":_vm.carrierRequests,"passengersStatuses":_vm.passengersStatuses,"loading":_vm.loading,"open":_vm.open},on:{"call":_vm.checkCreditAndCall,"hasBeenTaken":_vm.hasBeenTaken}}):_vm._e(),(!_vm.isMobile && _vm.carrierRequests.length)?_c('desktop',{attrs:{"data":_vm.carrierRequests,"passengersStatuses":_vm.passengersStatuses}}):_vm._e(),(!_vm.isIOS)?_c('div',{class:{
            mtdefault: _vm.isInstalled,
            mtdefaultMobile: !_vm.isInstalled
        }},[_c('tips',{staticClass:"pl16 pr16 pb16",staticStyle:{"padding-top":"16px"}})],1):_vm._e(),(_vm.showLoading)?_c('div',{staticClass:"loading-overlay"},[_c('div',{staticClass:"wrapper"},[_c('md-progress-spinner',{attrs:{"md-mode":"indeterminate","md-stroke":2}})],1)]):_vm._e(),_c('snackbar',{attrs:{"show":_vm.isTakenByOther,"text":_vm.passengerHasBeenTakenBy}}),_c('snackbar',{attrs:{"show":_vm.showCalling,"text":_vm.passengerIsTalkingMessage}}),_c('too-little-credit-modal',{attrs:{"show":_vm.tooLittleCredit,"carrierUUID":_vm.currentUser.carrierUUID},on:{"closeTooLittleCredit":function () { return (_vm.tooLittleCredit = false); }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }