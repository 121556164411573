<template>
    <md-snackbar
        :md-position="position"
        :md-duration="duration"
        :md-active.sync="showSnackbar"
        md-persistent
    >
        <span>{{ text }}</span>
    </md-snackbar>
</template>

<script>
export default {
    props: {
        show: { type: Boolean, default: false },
        text: { type: String, default: '' }
    },

    data() {
        return {
            position: 'center',
            duration: 5000,
            isInfinity: true,
            showSnackbar: false
        };
    },

    watch: {
        show() {
            this.showSnackbar = this.show;
        }
    }
};
</script>
