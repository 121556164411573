<template>
    <div>
        <md-dialog
            :md-fullscreen="false"
            :md-active="show"
            :md-click-outside-to-close="false"
        >
            <md-dialog-title>
                {{ $t('modals.youHaveToLittlePoints') }}
            </md-dialog-title>
            <div class="c707070" v-html="$t('modals.buyMorePointsMessage')" />

            <div>
                <md-dialog-actions>
                    <md-button
                        class="md-dense md-raised md-primary pull-right mt20"
                        @click="buy"
                    >
                        {{ waitingBuy ? '' : $t('modals.buy') }}
                        <img
                            v-if="waitingBuy"
                            src="@assets/images/loader.gif"
                            alt="loader"
                            title="loader"
                        />
                    </md-button>

                    <md-button
                        class="md-primary"
                        @click="$emit('closeTooLittleCredit')"
                    >
                        {{ $t('modals.close') }}
                    </md-button>
                </md-dialog-actions>
            </div>
        </md-dialog>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    props: {
        show: {
            type: Boolean,
            default: false
        },

        carrierUUID: {
            type: String,
            default: ''
        }
    },

    data() {
        return {
            waitingBuy: 0,
            depositSent: false
        };
    },

    computed: {
        ...mapGetters({
            currentUser: 'auth/currentUser'
        })
    },

    methods: {
        ...mapActions({
            buyPoints: 'payments/buyPoints'
        }),

        safari() {
            return !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
        },

        iphone() {
            if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
                return true;
            } else {
                return false;
            }
        },

        async buy() {
            this.waitingBuy = true;

            const params = {
                uuid: this.currentUser.carrierUUID,
                url: window.location.href,
                amount: 1000
            };

            try {
                const response = await this.buyPoints(params);
                const url = response.data.toString();

                setTimeout(() => {
                    if (this.iphone()) {
                        window.open(url, '_self');
                    } else if (this.safari()) {
                        window.open(url, '_self');
                    } else {
                        window.open(url);
                    }
                }, 100);

                this.waitingBuy = false;
            } catch (error) {
                this.$toast.error(this.$t('global.somethingWrong'));
            }
        }
    }
};
</script>
